import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ImportExportRounded, ArrowRightAltRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, MoreVert } from "@mui/icons-material";
import FileSaverExcel from '../../../components/FileSaverExcel';
import SearchBarWebex from './SearchBarWebex';
import { useTranslation } from "react-i18next";
import TreeChart from './TreeChart/TreeChart';

export default function TableComponentStatistics({ tableData, tableColumns }) {
    const [sortConfig, setSortConfig] = useState({ column: null, direction: 'asc' });
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [firstCalls, setFirstCall] = useState([]);
    const [allCalls, setAllCalls] = useState({});
    const [allCallsFormatted, setAllCallsFormatted] = useState();
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const { t } = useTranslation();

    useEffect(() => {
        let { firstCallsData, allCallsData } = filterDataByFirstCall(tableData);

        if (firstCallsData.length > 0) {
            filters.forEach(filter => {
                firstCallsData = firstCallsData.filter(item => filter.values.includes(item[filter.column]));
            });
        }

        let flattenedAllCalls = Object.values(allCallsData).flatMap(legs =>
            legs.flatMap(leg => flattenLegs(leg))
        );

        if (flattenedAllCalls.length > 0) {
            filters.forEach(filter => {
                flattenedAllCalls = flattenedAllCalls.filter(item => filter.values.includes(item[filter.column]));
            });
        }

        // Formater les données aplaties pour affichage ou exportation
        const formattedData = flattenedAllCalls.map(call => ({
            'Date de début': call.starttime || 'NA',
            'Localisation' : call.location || 'N/A',
            'Numéro appelant': call.callingnumber || 'NA',
            'Numéro appelé': call.callednumber || 'NA',
            'Ligne appelée': call.calledlineid || 'NA',
            'Ligne appelante': call.callinglineid || 'NA',
            'Résultat de l\'appel': call.calloutcome === "Success" ? "Succès" :
                call.calloutcome === "Refusal" ? "Refusé" :
                    call.calloutcome === "Failure" ? "Échec" : 'NA',
            'Type d\'appel': call.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                call.calltype === 'SIP_NATIONAL' ? 'National' :
                    call.calltype === 'SIP_MOBILE' ? 'Mobile' :
                        call.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                            call.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                call.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                    call.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                        call.calltype === 'SIP_MEETING' ? 'Réunion' : 'NA',
            'Durée (s)': call.duration || 'NA',
            'Indicateur de réponse': call.answerindicator === "Yes" ? "Oui" :
                call.answerindicator === "No" ? "Non" :
                    call.answerindicator === "Yes-PostRedirection" ? "Redirection-d'appel" : 'NA',
            'Correlation': call.correlationId || 'N/A'
        }));

        setAllCallsFormatted(formattedData);
        setFirstCall(firstCallsData);
        setAllCalls(allCallsData);
        setCurrentPage(1);
    }, [filters, tableData]);

    const flattenLegs = (leg) => {
        // Conserver tous les appels du leg actuel (premier niveau) sans filtrage
        const currentLegCalls = leg.calls || [];

        // Filtrer et aplatir les appels des enfants avec direction 'ORIGINATING'
        const childrenCalls = leg.children ? leg.children.flatMap(child => flattenChildrenWithOriginating(child)) : [];

        // Filtrer et aplatir les appels des subchildren avec direction 'ORIGINATING'
        const subchildrenCalls = leg.subchildren ? leg.subchildren.flatMap(subchild => flattenChildrenWithOriginating(subchild)) : [];

        // Retourner les appels du leg actuel concaténés avec les enfants et subchildren filtrés
        return currentLegCalls.concat(childrenCalls, subchildrenCalls);
    };

    const flattenChildrenWithOriginating = (leg) => {
        // Filtrer uniquement les appels avec direction 'ORIGINATING'
        const originatingCalls = leg.calls ? leg.calls.filter(call => call.direction === 'ORIGINATING') : [];

        // Continuer de manière récursive avec les enfants et subchildren
        const childrenCalls = leg.children ? leg.children.flatMap(flattenChildrenWithOriginating) : [];
        const subchildrenCalls = leg.subchildren ? leg.subchildren.flatMap(flattenChildrenWithOriginating) : [];

        // Retourner les appels filtrés des enfants et subchildren
        return originatingCalls.concat(childrenCalls, subchildrenCalls);
    };

    const filterDataByFirstCall = useCallback((data) => {
        let firstCallsData = [];
        let allCallsData = {};

        data.forEach(entry => {
            const correlationId = entry.correlationid;
            allCallsData[correlationId] = [];

            Object.values(entry.legs).forEach(leg => {
                //Gestion de firstCalls
                if (leg.calls?.length > 0) {
                    const firstCall = leg.calls[0];
                    firstCallsData.push({
                        ...firstCall,
                        entryId: correlationId,
                        isAnswered: checkAnswerIndicator(leg)
                    });
                }
                //Gestion de AllCalls(Legs)
                const legStructure = extractAllCalls(leg, correlationId); // Assurez-vous de passer correlationId ici
                allCallsData[correlationId].push(legStructure);
            });
        });

        return { firstCallsData, allCallsData };
    }, []);

    const checkAnswerIndicator = useCallback((data) => {
        // Fonction pour vérifier si un appel a un answerindicator "Yes" ou "Yes-PostRedirection"
        const hasYesCall = (calls) => {
            const yesPostRedirection = calls.some(call => call.answerindicator === "Yes-PostRedirection");
            const yes = calls.some(call => call.answerindicator === "Yes");
            return yesPostRedirection ? "Yes-PostRedirection" : yes ? "Yes" : "No";
        };
        // Fonction récursive pour traverser l'arbre
        const traverse = (node) => {
            let currentResult = hasYesCall(node.calls);
            // Vérifiez s'il y a des children
            if (node.children && Object.keys(node.children).length > 0) {
                // Parcourir tous les children et vérifier leur résultat
                const childrenResults = Object.values(node.children).map(traverse);
                // Si tous les children retournent "No", le résultat est "No"
                if (childrenResults.every(result => result === "No")) return "No";
                // Si au moins un child retourne "Yes-PostRedirection", le résultat est "Yes-PostRedirection"
                if (childrenResults.includes("Yes-PostRedirection")) return "Yes-PostRedirection";
                // Si au moins un child retourne "Yes", le résultat est "Yes"
                if (childrenResults.includes("Yes")) return "Yes";
            }
            // Vérifiez s'il y a des subchildren
            if (node.subchildren && Object.keys(node.subchildren).length > 0) {
                // Parcourir tous les subchildren et vérifier leur résultat
                const subchildrenResults = Object.values(node.subchildren).map(traverse);
                // Si tous les subchildren retournent "No", le résultat est "No"
                if (subchildrenResults.every(result => result === "No")) return "No";
                // Si au moins un subchild retourne "Yes-PostRedirection", le résultat est "Yes-PostRedirection"
                if (subchildrenResults.includes("Yes-PostRedirection")) return "Yes-PostRedirection";
                // Si au moins un subchild retourne "Yes", le résultat est "Yes"
                if (subchildrenResults.includes("Yes")) return "Yes";
            }
            // Si aucun child ou subchild n'existe, retourner le résultat actuel
            return currentResult;
        };
        // Commencer la traversée à partir de la racine des données
        return traverse(data);
    }, []);

    const extractAllCalls = useCallback((leg, correlationId) => {
        const legStructure = {
            calls: leg.calls?.map(call => ({ ...call, correlationId })) || [],
            children: leg.children ? Object.values(leg.children).map(child => extractAllCalls(child, correlationId)) : [],
            correlationId: correlationId
        };

        if (leg.subchildren) {
            legStructure.children = legStructure.children.concat(
                Object.values(leg.subchildren).map(subchild => extractAllCalls(subchild, correlationId))
            );
        }

        return legStructure;
    }, []);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.column === key) {
            direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ column: key, direction });
    };

    const sortedData = () => {
        let filteredData = firstCalls;
        filters.forEach(filter => {
            filteredData = filteredData.filter(item => filter.values.includes(item[filter.column]));
        });

        if (sortConfig.column !== null) {
            filteredData = filteredData.sort((a, b) => {
                if (a[sortConfig.column] < b[sortConfig.column]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.column] > b[sortConfig.column]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }

        return filteredData;
    };

    const paginatedData = useMemo(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        return sortedData().slice(start, end);
    }, [sortedData, currentPage, itemsPerPage]);

    const toggleRowExpansion = useCallback((entryId) => {
        setExpandedRows(prevRows => {
            const newRows = new Set(prevRows);
            newRows.has(entryId) ? newRows.delete(entryId) : newRows.add(entryId);
            return newRows;
        });
    }, []);

    const hasGreenNode = (leg) => {
        if (leg.calls.some(call => call.answerindicator === 'Yes')) return true;
        return leg.children?.some(child => hasGreenNode(child)) || false;
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const totalPages = Math.ceil(sortedData().length / itemsPerPage);

    return (
        <div className="container-listing-statistics">
            <div className="page-table-webex-header">
                <h2>Historique détaillé des appels</h2>
                <div className='tooltip-csv-tableWebex'>
                    <MoreVert />
                    <span className='tooltip-csv-tableWebex-button'><FileSaverExcel data={[allCallsFormatted]} name={'Detailed call history'} /></span>
                </div>
            </div>
            <div>
                <SearchBarWebex dataWebex={firstCalls} dataWebexColumns={tableColumns} filters={filters} setFilters={setFilters} statusAnswered={true} />
            </div>
            <section className="statistics-device-container">
                <div className="statistics-table">
                    <div className="statistics-table-wrapper">
                        <table cellPadding="0" cellSpacing="0" border="0">
                            <thead className="statistics-table-wrapper-header">
                                <tr>
                                    <th><KeyboardArrowDownRounded /></th>
                                    {tableColumns.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {item.headerColumn && (
                                                <th key={index}>
                                                    <div className="statistics-table-wrapper-header-content" onClick={() => requestSort(item.accessor)}>
                                                        {item.Header}
                                                        <div className='statistics-table-wrapper-header-content-container-icons'>
                                                            {sortConfig.column !== item.accessor && (
                                                                <span className='statistics-table-wrapper-header-content-icon'>
                                                                    <ImportExportRounded />
                                                                </span>
                                                            )}
                                                            <span>
                                                                {sortConfig.column === item.accessor && (
                                                                    sortConfig.direction === 'asc' ? (
                                                                        <span className='statistics-table-wrapper-header-content-icon-asc'><ArrowRightAltRounded /></span>
                                                                    ) : sortConfig.direction === 'desc' && (
                                                                        <span className='statistics-table-wrapper-header-content-icon-desc'><ArrowRightAltRounded /></span>
                                                                    )
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="statistics-table-wrapper-content">
                                {firstCalls.length > 0 ? (
                                    paginatedData.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td className='statistics-table-wrapper-content-element'>
                                                    <div className='legsCallingOpen' onClick={() => toggleRowExpansion(data.entryId)}>
                                                        {expandedRows.has(data.entryId) ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                                                    </div>
                                                </td>
                                                <td className='statistics-table-wrapper-content-element'>{data.starttime || 'NA'}</td>
                                                <td className='statistics-table-wrapper-content-element'>{data.location || 'NA'}</td>
                                                <td className='statistics-table-wrapper-content-element'>{data.callingnumber || 'NA'}</td>
                                                <td className='statistics-table-wrapper-content-element'>{data.callednumber || 'NA'}</td>
                                                <td className='statistics-table-wrapper-content-element'>{data.calledlineid || 'NA'}</td>
                                                <td className='statistics-table-wrapper-content-element'>{data.callinglineid || 'NA'}</td>
                                                <td className='statistics-table-wrapper-content-element'>
                                                    {
                                                        data.calloutcome === "Success" ? "Succès" :
                                                            data.calloutcome === "Refusal" ? "Refusé" :
                                                                data.calloutcome === "Failure" ? "Échec" : 'NA'
                                                    }
                                                </td>
                                                <td className='statistics-table-wrapper-content-element'>
                                                    {
                                                        data.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                                                            data.calltype === 'SIP_NATIONAL' ? 'National' :
                                                                data.calltype === 'SIP_MOBILE' ? 'Mobile' :
                                                                    data.calltype === 'UNKNOWN' ? 'Inconnu' :
                                                                        data.calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                                                            data.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                                                                data.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                                                                    data.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                                                                        data.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                                                                            data.calltype === 'SIP_MEETING' ? 'Réunion' : data.calltype
                                                                                                || 'NA'
                                                    }
                                                </td>
                                                <td className='statistics-table-wrapper-content-element'>{data.duration || 'NA'}</td>
                                                <td className={`statistics-table-wrapper-content-element ${data.isAnswered === 'Yes' ? 'statistics-table-wrapper-content-element-color-green' : data.isAnswered === 'Yes-PostRedirection' ? 'statistics-table-wrapper-content-element-color-refusal' : 'statistics-table-wrapper-content-element-color-red'}`}>
                                                    {
                                                        data.isAnswered === "Yes" ? "Oui" :
                                                            data.isAnswered === "No" ? "Non" : "Redirection-d'appel"
                                                                || 'NA'
                                                    }
                                                </td>
                                            </tr>
                                            {expandedRows.has(data.entryId) && (
                                                <tr key={`expanded-row-${data.entryId}`}>
                                                    <td colSpan={15} className="expanded-details">
                                                        <TreeChart data={allCalls[data.entryId][0]} />
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={15} className='noAvailableDataCalling'>
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-controls-webex-statistics">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>{t('Précédent')}</button>
                        <span>Page {currentPage} {t('sur')} {totalPages}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>{t('Suivant')}</button>
                    </div>
                </div>
            </section>
        </div>
    );
}