import React, { useEffect, useState } from "react";
import Loader from "../../../../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TypePostCallQueue({ data, callQueue, people, analyseType, setDataGraph, setFormattedHeader }) {
    const [groupedCalls, setGroupedCalls] = useState([]);
    const [groupPostSurvey, setGroupPostSurvey] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const { t, i18n } = useTranslation();
        
    useEffect(() => {
        if(groupedCalls.length > 0) {
           handleGraphgroupPostSurvey(groupedCalls); 
        }
    }, [groupedCalls]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    useEffect(() => {
        if (data && callQueue) {
            const groupementNumbers = Object.values(callQueue);
            // Fonction pour trier les appels dans chaque groupe
            const groupCallsByGroupement = (dataGroups) => {
                return dataGroups.map((dataGroup) => {
                    const { calls } = dataGroup; // Récupérer le tableau d'appels
                    const sortedCalls = [];

                    // Parcourir chaque appel pour identifier les groupes
                    calls.forEach((call) => {
                        // Vérifier si l'appel a un callednumber qui appartient à un groupement
                        if (groupementNumbers.includes(call.usernumber) && !groupementNumbers.includes(call.callednumber)) {
                        //     // Ajouter l'appel principal au tableau trié
                            sortedCalls.push({ ...call }); // Marquer cet appel comme un appel de groupement
                        }
                    });
                    // Retourner le groupe avec les appels triés
                    return sortedCalls.length > 0 ? {
                        ...dataGroup,
                        calls: sortedCalls
                    } : null; // Retourne null si aucun appel trouvé
                })
                .filter(group => group !== null); // Filtrer les groupes null
            };

            // Appliquer cette logique aux groupes de données
            const result = groupCallsByGroupement(data);
            // Mettre à jour l'état avec les appels groupés
            setGroupedCalls(result);
        }
    }, [data, callQueue]);

    const handleGraphgroupPostSurvey = (data) => {
        let callqueue = {};
        const peopleMap = people.flat().reduce((acc, person) => {
            const fullName = `${person.firstName} ${person.lastName}`;
            acc[person.phone.work] = fullName;
            if (person.phone.mobile) {
                acc[person.phone.mobile] = fullName;
            }
            return acc;
        }, {});
        
        data.map(item => {
            if (Array.isArray(item.calls)) {
                return item.calls.filter(call => {
                    const group = call.callednumber;
                    const person = peopleMap[group]; // Trouve la personne par numéro de téléphone
                    if (!person) return; // Ignore si personne n'est trouvée

                    if (!callqueue[person]) {
                        callqueue[person] = {
                            Outgoing: {
                                total: 0,
                                durationTotal: 0,
                                averageduration: 0,
                                response: 0,
                                averageresponse: 0,
                                noresponse: 0,
                                ringduration: 0,
                                averageringduration: 0
                            },
                            Incoming: {
                                total: 0,
                                durationTotal: 0,
                                averageduration: 0,
                                response: 0,
                                averageresponse: 0,
                                noresponse: 0,
                                ringduration: 0,
                                averageringduration: 0
                            }
                        };
                    }
                    if (call.direction === "TERMINATING") {
                        callqueue[person].Outgoing.total += 1;
                        callqueue[person].Outgoing.durationTotal += Math.floor(parseInt(call.duration) || 0);
                        callqueue[person].Outgoing.ringduration += Math.floor(parseInt(call.ringduration) || 0);
                        if (call.answerindicator === "Yes") {
                            callqueue[person].Outgoing.response += 1;
                            callqueue[person].Outgoing.averageresponse += Math.floor(parseInt(call.ringduration) || 0);
                        } else if (call.answerindicator === "No") {
                            callqueue[person].Outgoing.noresponse += 1;
                        }
                    } else if (call.direction === "ORIGINATING") {
                        callqueue[person].Incoming.total += 1;
                        callqueue[person].Incoming.durationTotal += Math.floor(parseInt(call.duration) || 0);
                        callqueue[person].Incoming.ringduration += Math.floor(parseInt(call.ringduration) || 0);
                        if (call.answerindicator === "Yes") {
                            callqueue[person].Incoming.response += 1;
                            callqueue[person].Incoming.averageresponse += Math.floor(parseInt(call.ringduration) || 0);
                        } else if (call.answerindicator === "No") {
                            callqueue[person].Incoming.noresponse += 1;
                        }
                    }
                });
              }
              return [];
        });

        for (let group in callqueue) {
            let Outgoing = callqueue[group].Outgoing;
            let Incoming = callqueue[group].Incoming;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;
            Outgoing.averageringduration = Outgoing.ringduration > 0 ? Outgoing.response + Outgoing.noresponse / Outgoing.ringduration : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;
            Incoming.averageringduration = Incoming.ringduration > 0 ? Incoming.response + Incoming.noresponse / Incoming.ringduration : 0;

            Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
            Outgoing.averageduration = formatDuration(Outgoing.averageduration);
            Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);
            Outgoing.ringduration = formatDuration(Outgoing.ringduration);
            Outgoing.averageringduration = formatDuration(Outgoing.averageduration);

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);
            Incoming.ringduration = formatDuration(Incoming.ringduration);
            Incoming.averageringduration = formatDuration(Incoming.averageringduration);
        }
        setGroupPostSurvey(callqueue);
        const formattedData = formatForExcel(callqueue);
        setDataGraph(formattedData);
    }

    const formatForExcel = (data) => {
        // En-têtes des colonnes
        const headers = ["Poste"];  // Le premier en-tête est "Poste"
    
        const keysMapping = [
            { key: "response", label: "Nombre d'appels répondu" },
            { key: "averageresponse", label: "Durée moyenne pour répondre" },
            { key: "noresponse", label: "Nombre d'appels non répondu" },
            { key: "durationTotal", label: "Durée totale d'appels" },
            { key: "averageduration", label: "Durée moyenne d'appels" },
            { key: "ringduration", label: "Durée totale d'attente" },
            { key: "averageringduration", label: "Durée moyenne d'attente" }
        ];
    
        // Ajouter les labels dans les en-têtes
        keysMapping.forEach(({ label }) => {
            headers.push(label);
        });
    
        // Format des données pour Excel : première ligne = headers
        const excelData = [headers];
    
        // Parcourir chaque groupe (Support, Standard, etc.)
        Object.keys(data).forEach(group => {
            // Créer une ligne pour chaque groupe
            const row = [group];  // La première colonne est le nom du groupe (poste)
    
            // Ajouter les valeurs correspondantes à chaque label (Incoming uniquement ici)
            keysMapping.forEach(({ key }) => {
                const value = data[group].Incoming[key] !== undefined ? data[group].Incoming[key] : "N/A";
                row.push(value);  // Ajouter la valeur à la ligne
            });
    
            // Ajouter la ligne complète à excelData
            excelData.push(row);
        });
    
        setFormattedHeader(true);
    
        return excelData;
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    return (
        <div className="localitySurvey">
            {groupPostSurvey ? (
                <>
                <div className="pagination-surveyPost">
                        <button
                            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
                            disabled={currentPage === 1}
                        >
                            {t("Précédent")}
                        </button>
                        <button
                            onClick={() =>
                                paginate(
                                    currentPage < Math.ceil(Object.keys(groupPostSurvey).length / itemsPerPage)
                                        ? currentPage + 1
                                        : currentPage
                                )
                            }
                            disabled={currentPage === Math.ceil(Object.keys(groupPostSurvey).length / itemsPerPage)}
                        >
                            {t("Suivant")}
                        </button>
                    </div>
                <div>
                    <table className="localitySurvey-entrants">
                        <thead>
                            <tr>
                                <th title="Poste">{t("Poste")}</th>
                                <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                <th title="Durée totale d'attente">{t("Durée totale d'attente")}</th>
                                <th title="Durée moyenne d'attente">{t("Durée moyenne d'attente")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupPostSurvey)
                                .slice(indexOfFirstItem, indexOfLastItem)
                                .map((group, index) => (
                                <tr key={index}>
                                    <td>{group}</td>
                                    <td>{groupPostSurvey[group].Incoming.response}</td>                            
                                    <td>{groupPostSurvey[group].Incoming.averageresponse}</td>
                                    <td>{groupPostSurvey[group].Incoming.noresponse}</td>
                                    <td>{groupPostSurvey[group].Incoming.durationTotal}</td>                             
                                    <td>{groupPostSurvey[group].Incoming.averageduration}</td>
                                    <td>{groupPostSurvey[group].Incoming.ringduration}</td>
                                    <td>{groupPostSurvey[group].Incoming.averageringduration}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                </>
            ) : (
                <Loader/>
            )}
        </div>
    );
}
