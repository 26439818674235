import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../../context/AuthContext";
import { WebexStatisticsColumn } from "../../../components/headerContent";
import ChartsAllStatistics from "./TypeCharts/ChartsAllStatistics";
import TableComponentStatistics from "./TableCompnentStatistics";
import SearchBarWebex from "./SearchBarWebex";
import StatisticsBarWebex from "./StatisticsBarWebex";
import StatisticsReportWebex from "./Rapport/StatisticsReportWebex";
import CallQueue from "./CallQueue/CallQueue";
import useWindowSize from "../../../components/WindowSize";
import { DateRangePicker, Progress } from 'rsuite';
import { startOfDay, endOfDay, addDays, addMonths, subDays, startOfMonth, endOfMonth } from 'date-fns';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FileDownload, MoreVert } from "@mui/icons-material";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const Ranges = [
    { label: 'Aujourd\'hui', value: [new Date(), new Date()], placement: 'left' },
    { label: 'Hier', value: [addDays(new Date(), -1), addDays(new Date(), -1)], placement: 'left' },
    { label: 'Les 7 derniers jours', value: [subDays(new Date(), 6), new Date()], placement: 'left' },
    { label: 'Les 30 derniers jours', value: [subDays(new Date(), 29), new Date()], placement: 'left' },
    { label: 'Ce mois-ci', value: [startOfMonth(new Date()), new Date()], placement: 'left' },
    { label: 'Le mois dernier', value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))], placement: 'left' }
];

const originalLabels = {
    "Location": "location",
    "Answered": "answerindicator",
    "Calltype": "calltype",
    "Direction": "direction"
};

export default function StatisticsWebex() {
    const { apiUrl } = useContext(AuthContext);
    const queryClient = useQueryClient();
    const [dataWebexHistoryCall, setDataWebexHistoryCall] = useState(null);
    const [filters, setFilters] = useState([]);
    const [allData, setAllData] = useState([]);
    const [firstData, setFirstData] = useState([]);
    const [firstDataTable, setFirstDataTable] = useState([]);
    const [linkPath, setLinkPath] = useState('statistics');
    const [loading, setLoading] = useState(false);
    const [messageRefresh, setMessageRefresh] = useState('');
    const [loadingPercent, setLoadingPercent] = useState(0);
    const [loadingColor, setLoadingColor] = useState('#3385ff');
    const today = endOfDay(new Date(), 23);
    const week = startOfDay(subDays(today, 14));//test : (today, 70)
    const { afterToday, combine } = DateRangePicker;
    const [date, setDate] = useState([week, today]);
    const [minDate, setMinDate] = useState(null);
    const windowSize = useWindowSize();
    const placement = windowSize.width < 945 ? 'bottomStart' : 'bottomEnd';
    const [isRefresh, setIsRefresh] = useState(false);
    const chartsRef = useRef();
    const { t } = useTranslation();

    const getWebexHistory = async () => {
        try {
            const res = await axios.get(`${apiUrl}webex/webexHistoryCall`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const webexHistoryCall = useQuery({
        queryKey: ["webexHistoryCall"],
        queryFn: getWebexHistory,
    });

    const getWebexPeople = async () => {
        try {
            const res = await axios.get(`${apiUrl}webex/people`, {
                withCredentials: true
            });
            return res.data;
        } catch (err) {

        }
    }

    const webexPeople = useQuery({
        queryKey: ["webexPeople"],
        queryFn: getWebexPeople,
    });

    const getRefresh = async () => {
        try {
            const res = await axios.get(`${apiUrl}webex/historyCall`, {
                withCredentials: true,
            });
            setMessageRefresh('Les appels ont été mis à jour avec succès !');
            setIsRefresh('update');
            return res.data;
        } catch (error) {
            if (error.response?.data?.message?.startsWith("Too many requests sent for this OrgId")) {
                // Trop de requêtes envoyées, afficher le message correspondant
                setMessageRefresh('Veuillez patienter un moment avant de réessayer !');
                setIsRefresh('reset');
            } else {
                setMessageRefresh('Erreur lors du rafraîchissement des appels !');
                setIsRefresh('delete');
            }
        }
    };
    
    const { mutate: refresh, isLoading } = useMutation(getRefresh, {
        onMutate: () => {
            setLoading(true); // Start loading
            setLoadingPercent(0); // Reset progress
            setLoadingColor('#124188'); // Initial loading color
            const interval = setInterval(() => {
                setLoadingPercent((prev) => {
                    const newPercent = Math.min(prev + 5, 100); // Increase loading percentage but cap at 90%
                    return newPercent;
                });
            }, 100); // Adjust interval as needed
    
            return () => clearInterval(interval); // Cleanup
        },
        onSuccess: () => {
            setLoading(false); // Stop loading
            setLoadingColor('#AEC353');
        },
        onError: () => {
            setLoading(false); // Stop loading
            setLoadingColor('#fff3f3'); // Red color for error
        }
    });    

    useEffect(() => {
        queryClient.invalidateQueries(["webexHistoryCall", webexHistoryCall.data]);
        if (webexHistoryCall.data && webexPeople.data) {
            const earliestDate = new Date(webexHistoryCall.data[1]);
            setMinDate(earliestDate);
            const filteredDatabyPeople = dataPeople(webexHistoryCall.data[0], webexPeople.data);
            const filteredDataFirstCall = filterDataByFirstCall(filteredDatabyPeople);
            const filteredDataAllCalls = filterDataByAllCalls(filteredDatabyPeople);
            setFirstData(filterFirstDataByPeriod(filteredDataFirstCall));
            setFirstDataTable(filterDataByPeriodFirstCallTable(filteredDataFirstCall));
            setAllData(filteredDataAllCalls);
            getUtilizationInterfaceGraph(filterDataByPeriodFirstCall(filteredDataFirstCall));
        }
    }, [webexHistoryCall.data, webexPeople.data, date]);

    const getUtilizationInterfaceGraph = (dataWebexHistoryCall) => {
        let data = null;
        data = dataWebexHistoryCall.filter(item => { return item });
        setDataWebexHistoryCall(dataWebexHistoryCall);
    }

    const getDates = () => {
        const [startDate, adjustedEndDate] = date;
        const endDate = endOfDay(adjustedEndDate, 23);
        return { startDate, endDate };
    };

    const dataPeople = (data, people) => {
        const peopleMap = new Map();

        people.forEach(person => {
            if (person.phone && person.phone.work) {
                peopleMap.set(person.phone.work, `${person.firstName} ${person.lastName}`);
            }
        });

        const updateCallingLineId = (calls) => {
            calls.forEach(call => {
                const personInfoCalling = peopleMap.get(call.callingnumber);
                const personInfoCalled = peopleMap.get(call.callednumber);
                if (personInfoCalling) {
                    call.callinglineid = personInfoCalling;
                }
                if (personInfoCalled) {
                    call.calledlineid = personInfoCalled;
                }
            });
        };

        const updatedData = Object.values(data).map(item => {
            Object.values(item.legs).forEach(leg => {
                // Mettre à jour les appels dans `leg.calls`
                updateCallingLineId(leg.calls);

                // Vérifier et mettre à jour les enfants s'ils existent
                if (leg.children) {
                    Object.values(leg.children).forEach(child => {
                        updateCallingLineId(child.calls);
                    });
                }
            });
            return item;
        });

        return updatedData;
    }

    const filterDataByFirstCall = (data) => {
        return data.map(entry => {
            const filteredLegs = Object.entries(entry.legs).reduce((acc, [key, leg]) => {
                if (leg.calls && leg.calls.length > 0) {
                    acc[key] = { ...leg };
                }
                return acc;
            }, {});
            return {
                ...entry,
                legs: filteredLegs
            };
        }).filter(entry => Object.keys(entry.legs).length > 0);
    };

    const filterDataByAllCalls = (data) => {
        const traverseAndExtractValidCalls = (node, correlationid) => {
            let calls = [];
            
            if (node.calls && node.calls.length > 0) {
                const callsWithCorrelationId = node.calls.map(call => ({
                    ...call,
                    correlationid 
                }));
                calls = calls.concat(callsWithCorrelationId );
            }

            if (node.children) {
                for (const child of Object.values(node.children)) {
                    const childCalls = traverseAndExtractValidCalls(child, correlationid);
                    if (childCalls) {
                        calls = calls.concat(childCalls);
                    } else if (!node.calls || node.calls.length === 0) {
                        return null; // Invalid child: no calls and no parent calls
                    }
                }
            }

            return calls.length > 0 ? calls : null;
        };

        const filteredData = data.flatMap((entry) => {
            const correlationid = entry.correlationid;
            const legCalls = Object.values(entry.legs).flatMap(leg => traverseAndExtractValidCalls(leg, correlationid));
            return legCalls;
        }).filter(Boolean);

        return filteredData.length > 0 ? filteredData : [{ error: "No valid data" }];
    };

    const filterFirstDataByPeriod = (data) => {
        return data.flatMap(entry => {
            const legEntries = Object.values(entry.legs);
            const firstLeg = legEntries[0]; // Sélectionner le premier `leg`
            if (!firstLeg) return []; // Si aucun `leg` n'est présent, retourner un tableau vide

            return [firstLeg.calls[0]]; // Retourner les appels filtrés
        });
    };

    const filterDataByPeriodFirstCallTable = (data) => {
        const { startDate, endDate } = getDates();
        return data.filter(entry => {
            const legEntries = Object.entries(entry.legs);
            return legEntries.some(([key, leg]) => {
                return leg.calls.some(call => {
                    const callStartTime = new Date(call.starttime);
                    return callStartTime >= startDate && callStartTime <= endDate;
                });
            });
        });
    };

    const filterDataByPeriodFirstCall = (data) => {
        const { startDate, endDate } = getDates();
        return data.flatMap(entry => {
            const legEntries = Object.values(entry.legs);
            const firstLeg = legEntries[0]; // Sélectionner le premier `leg`
            if (!firstLeg) return []; // Si aucun `leg` n'est présent, retourner un tableau vide
            // Filtrer les appels dans le premier `leg`
            const filteredCalls = [firstLeg.calls[0]].filter(call => {
                const callStartTime = new Date(call.starttime);
                return callStartTime >= startDate && callStartTime <= endDate;
            });

            return filteredCalls; // Retourner les appels filtrés
        });
    };

    const generatePdf = async () => {
        const element = chartsRef.current;
        
        const canvas = await html2canvas(element, {
            scale: 1.5,
            ignoreElements: (el) => el.classList.contains('table-legs-webex-statistics-content')
        });

        const imgData = canvas.toDataURL("image/jpeg", 0.75);

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();

        // Définir une largeur stable pour toutes les images
        const stableWidth = 160; // Ajustez selon vos besoins
        const imgHeight = (imgProperties.height * stableWidth) / imgProperties.width; // Hauteur proportionnelle

        // Charger le logo
        const logoSrc = '/elitlogo.jpg'; // Remplacez par le chemin de votre logo
        try {
            const logo = await loadImage(logoSrc);

            // Ajouter le logo au PDF
            const logoWidth = 30; // Ajustez selon vos besoins
            const logoHeight = (logo.height * logoWidth) / logo.width; // Hauteur proportionnelle
            const logoX = 10; // Position X du logo
            const logoY = 10; // Position Y du logo

            pdf.addImage(logo, 'JPEG', logoX, logoY, logoWidth, logoHeight);

            // Définir les titres
            const title1 = "ECP - Rapport Interactif"; // Premier titre
            const title2 = "Statistics"; // Deuxième titre
            const titleMargin = 20; // Marge en haut pour le premier titre

            // Ajouter le premier titre
            pdf.setFontSize(18); // Taille de police pour le titre
            pdf.text(title1, pdfWidth / 2, titleMargin, { align: "center" }); // Titre centré

            // Ajouter le deuxième titre juste en dessous
            pdf.setFontSize(14); // Taille de police pour le sous-titre
            const title2Margin = titleMargin + 10; // Espace pour le deuxième titre
            pdf.text(title2, pdfWidth / 2, title2Margin, { align: "center" }); // Sous-titre centré

            // Centrer l'image horizontalement et ajuster position Y
            const positionX = (pdfWidth - stableWidth) / 2; // Centrer horizontalement
            const positionY = title2Margin + 10; // Position Y après le sous-titre

            // Ajouter l'image au PDF
            pdf.addImage(imgData, "JPEG", positionX, positionY, stableWidth, imgHeight);

            // Retourner le blob PDF
            pdf.save("interface-data.pdf");
        } catch (error) {
            console.error("Erreur lors du chargement de l'image du logo :", error);
        }
    };

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
        });
    };

    const handleNavigation = (path) => {
        setLinkPath(path);
    };

    const handleGraphClick = (event, chartRef) => {
        const chartInstance = chartRef;
        const activePoints = chartInstance.getElementsAtEventForMode(
            event,
            "nearest",
            { intersect: true },
            true
        );
        if (activePoints.length > 0) {
            let column;
            const firstPoint = activePoints[0];
            const untranslatedLabel = chartInstance.data.datasets[firstPoint.datasetIndex].untranslatedLabel;
            let filter = chartInstance.data.labels[firstPoint.index];
            if (filter === 'Entrant' || filter === 'Incoming') {
                filter = 'TERMINATING';
            } else if (filter === 'Sortant' || filter === 'Outgoing') {
                filter = 'ORIGINATING';
            } else if (filter === 'Oui') {
                filter = 'Yes';
            } else if (filter === 'Non') {
                filter = 'No';
            } else if (filter === 'Redirection-d\'appel' || filter === "Call redirection") {
                filter = 'Yes-PostRedirection';
            } else if (filter === 'Interne') {
                filter = 'SIP_ENTERPRISE';
            } else if (filter === 'National') {
                filter = 'SIP_NATIONAL';
            } else if (filter === 'Mobile') {
                filter = 'SIP_MOBILE';
            } else if (filter === 'Inconnu') {
                filter = 'UNKNOWN';
            } else if (filter === 'Prenium') {
                filter = 'SIP_PREMIUM';
            } else if (filter === 'International') {
                filter = 'SIP_INTERNATIONAL';
            } else if (filter === 'Entrant') {
                filter = 'SIP_INBOUND';
            } else if (filter === 'Opérateur') {
                filter = 'SIP_OPERATOR';
            } else if (filter === 'Sans frais') {
                filter = 'SIP_TOLLFREE';
            } else if (filter === 'Réunion') {
                filter = 'SIP_MEETING';
            }
            column = originalLabels[untranslatedLabel] || untranslatedLabel;
            setFilters(prevFilters => {
                const existingFilter = prevFilters.find(f => f.column === column);
                if (existingFilter) {
                    const updatedValues = Array.from(new Set([...existingFilter.values, filter]));
                    return prevFilters.map(f => f.column === column ? { ...f, values: updatedValues } : f);
                } else {
                    return [...prevFilters, { column, values: [filter] }];
                }
            });
        }
    };

    // if (webexHistoryCall.isFetched && webexHistoryCall.data && webexHistoryCall.data[0] === undefined || webexHistoryCall.isFetched && dataWebexHistoryCall && dataWebexHistoryCall.length === 0) {
    //     return <article className="page">
    //         <h1 className="page-title">
    //             {t('Aucune statistique disponible')}
    //         </h1>
    //     </article>
    // }

    const handleClickPopup = () => {
        setIsRefresh(false);
    }

    return (
        <>
            {!webexHistoryCall.isLoading && webexHistoryCall.data && dataWebexHistoryCall ? (
                <>
                    {isRefresh && (
                        <article className="page-timeline">
                            <div className={`timeline-webhook-container popup-${isRefresh}`}>
                                <button onClick={() => handleClickPopup()} className="close-alerts-status-length">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
                                    </svg>
                                </button>
                                <div>{messageRefresh}</div>
                            </div>
                        </article>
                    )}
                    <article className="page">
                        <div className="statistics-header-content">
                            <section className="page-logo">
                                <img src="./assets/webex_logo.png" alt="Logo de Cisco Webex" />
                                <h1 className="page-title">Statistics Webex</h1>
                            </section>
                            {linkPath === 'statistics' && (
                                <section className="choice-calendar-or-pdf">
                                    <DateRangePicker
                                        ranges={Ranges}
                                        cleanable={false}
                                        character=" / "
                                        value={date}
                                        onChange={setDate}
                                        format="dd-MM-yyyy"
                                        defaultValue={[week, today]}
                                        shouldDisableDate={combine(afterToday(), (date) => minDate && date < startOfDay(new Date(minDate)))}
                                        locale={{
                                            sunday: t('Su'),
                                            monday: t('Mo'),
                                            tuesday: t('Tu'),
                                            wednesday: t('We'),
                                            thursday: t('Th'),
                                            friday: t('Fr'),
                                            saturday: t('Sa'),
                                            hours: t('Hours'),
                                        }}
                                        placement={placement}
                                    />
                                    <div className='tooltip-csv-tableWebex'>
                                        <MoreVert />
                                        <span className='tooltip-csv-tableWebex-button'>
                                            <div onClick={generatePdf} className="saveCSVWebexCalling"><FileDownload /> {t("Save as PDF")}</div>
                                            <div 
                                                onClick={() => { refresh(); }}
                                                className="saveCSVWebexCalling"
                                            >
                                                <div className="content-refresh-button-excel">
                                                    <div className="name-refresh">
                                                        <FileDownload /> {t("Refresh calls")}
                                                    </div>
                                                    {loading && (
                                                        <div style={{ marginTop: '10px' }}>
                                                            <Progress.Line percent={loadingPercent} strokeColor={loadingColor} status={loadingPercent >= 50 ? 'active' : null} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </section>
                            )}
                        </div>
                        <div className="menu-statistics-webex">
                            <div onClick={() => handleNavigation('statistics')} className={linkPath === 'statistics' ? 'active' : ''}>{t("Detailed Call History")}</div>
                            <div onClick={() => handleNavigation('rapport')} className={linkPath === 'rapport' ? 'active' : ''}>{t("Rapport interactif")}</div>
                            <div onClick={() => handleNavigation('call_queue')} className={linkPath === 'call_queue' ? 'active' : ''}>{t("Call Queue")}</div>
                        </div>
                        {linkPath === 'statistics' && (
                            <div>
                                <SearchBarWebex dataWebex={dataWebexHistoryCall} dataWebexColumns={WebexStatisticsColumn} filters={filters} setFilters={setFilters} />
                            </div>
                        )}
                        {linkPath === 'statistics' ? (
                            <div>
                                <div className="container-bar-statistics">
                                    <StatisticsBarWebex data={dataWebexHistoryCall} dataAll={firstData} filters={filters} date={date} />
                                </div>
                                <div className="container-charts" ref={chartsRef}>
                                    <ChartsAllStatistics data={dataWebexHistoryCall} filters={filters} date={date} handleGraphClick={handleGraphClick} />
                                </div>
                                <div className="table-legs-webex-statistics-content">
                                    <TableComponentStatistics tableData={firstDataTable} tableColumns={WebexStatisticsColumn} />
                                </div>
                            </div>
                        ) : linkPath === 'rapport' ? (
                            <StatisticsReportWebex data={firstData} people={webexPeople.data} />
                        ) : (
                            <CallQueue data={allData} people={webexPeople.data} />
                        )}
                    </article>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}