import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const TreeChart = ({ data }) => {
    const svgRef = useRef();
    const [svgHeight, setSvgHeight] = useState(600);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const width = 1200;
        const margin = { top: 30, right: 0, bottom: 0, left: 40 };

        const innerWidth = width - margin.left - margin.right;

        // Helper function to remove nodes with only TERMINATING calls
        const filterHierarchy = (node) => {
            node.calls = node.calls ? node.calls.filter(call => call.direction !== 'TERMINATING') : [];

            if (node.children) {
                node.children = node.children
                    .map(filterHierarchy)
                    .filter(child => child !== null && (child.calls.length > 0 || child.children));
            }

            return node.calls.length > 0 || (node.children && node.children.length > 0) ? node : null;
        };

        const filteredData = filterHierarchy(JSON.parse(JSON.stringify(data))); // Deep copy data
        if (!filteredData) {
            svg.selectAll("*").remove();
            return;
        }

        const root = d3.hierarchy(filteredData, d => d.children);
        const treeLayout = d3.tree().size([innerWidth, svgHeight - margin.top - margin.bottom]);
        treeLayout(root);

        svg.selectAll("*").remove();

        const g = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const tooltip = d3.select("body").append("div")
            .style("position", "absolute")
            .style("background", "#fff")
            .style("border", "1px solid #ccc")
            .style("padding", "10px")
            .style("border-radius", "5px")
            .style("box-shadow", "0px 0px 2px rgba(0, 0, 0, 0.2)")
            .style("pointer-events", "none")
            .style("opacity", 0);

        // Draw links between the valid nodes
        g.selectAll('.link')
            .data(root.links())
            .enter()
            .append('path')
            .attr('class', 'link')
            .attr('d', d3.linkHorizontal()
                .x(d => d.x)
                .y(d => d.y))
            .attr('stroke', d => {
                const sourceCall = d.source.data.calls ? d.source.data.calls[0] : undefined;  // Appel associé au noeud source
                const targetCall = d.target.data.calls ? d.target.data.calls[0] : undefined;  // Appel associé au noeud cible

                // Fonction pour vérifier si un appel est valide (Yes ou Yes-PostRedirection)
                const isCallValid = call => call && (call.answerindicator === 'Yes' || call.answerindicator === 'Yes-PostRedirection');

                // Si le noeud source est valide ou si c'est un point de liaison menant à un noeud valide
                return (isCallValid(sourceCall) || !sourceCall) && (isCallValid(targetCall) || !targetCall)
                    ? '#AEC353' // Vert si la condition est satisfaite
                    : '#ccc';   // Couleur par défaut sinon
            })
            .attr('fill', 'none');

        // Draw nodes
        const nodes = g.selectAll('.node')
            .data(root.descendants())
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('transform', d => `translate(${d.x},${d.y})`);

        // Add circles for each node
        nodes.append('circle')
            .attr('r', 5)
            .attr('fill', d => {
                const call = d.data.calls[0];
                return call ? (call.answerindicator === 'Yes' || call.answerindicator === 'Yes-PostRedirection' ? '#AEC353' : '#ccc') : '#ccc';
            })
            .on('mouseover', function (event, d) {
                const call = d.data.calls[0];
                if (!call) return;

                const calloutOutcomeClass = call.calloutcome === 'Success' ? 'tooltip-webex-legs-success' :
                    call.calloutcome === 'Failure' ? 'tooltip-webex-legs-failure' :
                        'tooltip-webex-legs-refusal';
                const answerIndicatorClass = call.answerindicator === 'Yes' ? 'tooltip-webex-legs-success' :
                    call.answerindicator === 'Yes-PostRedirection' ? 'tooltip-webex-legs-redirect' :
                        'tooltip-webex-legs-refusal';
                const directionIndicatorClass = call.direction === 'ORIGINATING' ? 'tooltip-webex-legs-redirect' : 'tooltip-webex-legs-refusal';
                // <p>Final Remote Id: <span> ${call.finalremotesessionid}</span></p>
                // <p>Local Session Id: <span> ${call.localcallid}</span></p>
               
                // <p>Remote Call Id: <span> ${call.remotecallid}</span></p>
                // 
                tooltip.transition().duration(200).style("opacity", 1);
                tooltip.html(`
                     <p>Raison de redirection: <span> ${call.relatedreason}</span></p>
                <p>Numéro appelant: ${call.callingnumber || 'N/A'}</p>
                <p>Ligne appelante: ${call.callinglineid || 'N/A'}</p>
                <p>Numéro appelé: ${call.callednumber || 'N/A'}</p>
                <p>Ligne appelé: ${call.calledlineid || 'N/A'}</p>
                <p>Date de début: ${call.starttime || 'N/A'}</p>
                <p>Localisation: ${call.location || 'N/A'}</p>
                <p>Motif de redirection: ${call.redirectreason || 'N/A'}</p>
                <p>Type d'appel: 
                    ${call.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                    call.calltype === 'SIP_NATIONAL' ? 'National' :
                        call.calltype === 'SIP_MOBILE' ? 'Mobile' :
                            call.calltype === 'UNKNOWN' ? 'Inconnu' :
                                call.calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                    call.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                        call.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                            call.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                                call.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                                    call.calltype === 'SIP_MEETING' ? 'Réunion' : call.calltype
                                                        || 'NA'
                }
                </p>
                <p>Durée: ${call.duration || 'N/A'} secondes</p>
                <p>Durée de sonnerie: ${call.ringduration || 'N/A'} secondes</p>
                ${call.redirectingnumber ? `<p>Numéro de redirection: ${call.redirectingnumber}</p>` : ''}
                <p class="${calloutOutcomeClass}">
                    Résultat de l'appel: 
                    <span> 
                        ${call.calloutcome === "Success" ? "Succès" :
                    call.calloutcome === "Refusal" ? "Refusé" :
                        call.calloutcome === "Failure" ? "Échec" : 'NA'
                }
                    </span>
                </p>
                <p class="${answerIndicatorClass}">
                    Indicateur de réponse: 
                    <span> 
                        ${call.answerindicator === "Yes" ? "Oui" :
                    call.answerindicator === "No" ? "Non" : "Redirection-d'appel"
                        || 'NA'
                }
                    </span>
                </p>
                <p class="${directionIndicatorClass}">Direction: <span> ${call.direction}</span></p>
            `)
                    .style("left", (event.pageX + 10) + "px")
                    .style("top", (event.pageY + 10) + "px");
            })
            .on('mousemove', function (event) {
                tooltip.style("left", (event.pageX + 10) + "px")
                    .style("top", (event.pageY + 10) + "px");
            })
            .on('mouseout', function () {
                tooltip.transition().duration(200).style("opacity", 0);
            });

        // Add labels to each node with more detailed information
        nodes.append('text')
        .style('font-size', '11px')
        .style('fill', 'black')
        .selectAll('tspan')
        .data(d => {
            const call = d.data.calls[0];
            if (!call) return [];
            return [
                call.callednumber ? `${call.callednumber}` : '',
                call.calledlineid ? `${call.calledlineid}` : '',
                call.starttime ? `${call.starttime}` : ''
            ].filter(line => line); // Filtrer les lignes vides
        })
        .enter()
        .append('tspan')
        .attr('x', 10) // Assurez-vous que cela correspond à l'attribut dx
        .attr('dy', (d, i) => 15) // Ajuster l'espacement vertical
        .text(d => d);

        // Update the SVG height dynamically based on the new content
        const updateSvgHeight = () => {
            const bbox = g.node().getBBox();
            setSvgHeight(bbox.height + 30); // Add padding for safety
        };

        updateSvgHeight();

        // Clean up tooltips when the component unmounts
        return () => {
            tooltip.remove();
        };
    }, [data]);

    return <svg ref={svgRef} width="100%" height={svgHeight}></svg>;
};

export default TreeChart;
