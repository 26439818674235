import React, { useEffect, useState, useMemo } from "react";
import { ClearRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function PopupFilteringCallQueue({ data, filterValues, handleGroupChange, onSubmit, onCancel }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [groupsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredGroups, setFilteredGroups] = useState([]);
    const { t } = useTranslation();

    // Utiliser useMemo pour éviter de recréer groupsArray à chaque rendu
    const groupsArray = useMemo(() => Object.entries(data), [data]);

    useEffect(() => {
        // Filtrer les groupes en fonction du terme de recherche
        if (searchTerm.trim() === "") {
            setFilteredGroups(groupsArray); // Si aucun terme de recherche, afficher tous les groupes
        } else {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            const filtered = groupsArray.filter(([groupName, groupNumber]) =>
                groupName.toLowerCase().includes(lowerCaseSearchTerm) ||
                groupNumber.includes(searchTerm)
            );
            setFilteredGroups(filtered);
        }
        setCurrentPage(1); // Réinitialiser à la première page après une nouvelle recherche

    }, [searchTerm, groupsArray]);

    // Déterminer les groupes à afficher sur la page actuelle
    const indexOfLastGroup = currentPage * groupsPerPage;
    const indexOfFirstGroup = indexOfLastGroup - groupsPerPage;
    const currentGroups = filteredGroups.slice(indexOfFirstGroup, indexOfLastGroup);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleResetSearch = () => {
        setSearchTerm(""); // Réinitialiser la barre de recherche
    };

    const handleOK = () => {
        // Retourner les groupes sélectionnés lors de la validation
        onSubmit(filterValues.groups);
    };

    const handleCancel = () => {
        onCancel(); // Fonction d'annulation
    };
    
    return (
        <div className="popup-filtering-report-webex-statistics">
            <h2>{t("Sélection des valeurs")}</h2>
            <div className="container-search-bar-filtering-report-webex">
                <div>
                    {t("Filtrer par :")}
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button onClick={handleResetSearch}><ClearRounded /></button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t("Numéro")}</th>
                        <th>{t("Nom du groupe")}</th>
                    </tr>
                </thead>
                <tbody>
                    {currentGroups.map(([groupName, groupNumber], index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={Array.isArray(filterValues.groups) && filterValues.groups.includes(groupNumber)} // Vérifier si le groupe est sélectionné
                                    onChange={() => handleGroupChange(groupNumber)} // Gérer le changement
                                />
                            </td>
                            <td>{groupNumber}</td>
                            <td>{groupName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {filteredGroups.length > groupsPerPage && (
                <div className="pagination">
                    {[...Array(Math.ceil(filteredGroups.length / groupsPerPage)).keys()].map(number => (
                        <button key={number} onClick={() => paginate(number + 1)}>
                            {number + 1}
                        </button>
                    ))}
                </div>
            )}
            <div className="container-buttons-popup-filtering-report-webex">
                <button onClick={handleOK}>{t("OK")}</button>
                <button onClick={handleCancel}>{t("Annuler")}</button>
            </div>
        </div>
    );
}