import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Timeline } from '@knight-lab/timelinejs';
import '@knight-lab/timelinejs/dist/css/timeline.css';
import TableComponent from "../../components/TableComponent";
import { ProvisionningColumn } from "../../components/headerContent";
import NumberCard from "../../components/Card/NumberCard";
import { faShield, faCircleCheck, faKey, faFile } from "@fortawesome/free-solid-svg-icons";
import { dateProvisioning } from "../../components/utils";
import { useTranslation } from "react-i18next";
import RowTimelineEcp from "./RowTimelineEcp";
import { DateRangePicker } from 'rsuite';
import { startOfDay, endOfDay, addDays, addMonths, subDays, startOfMonth, endOfMonth, parseISO, isWithinInterval } from 'date-fns';
import Loader from "../../components/Loader";

export default function TimelineEcp() {
    const { apiUrl, user } = useContext(AuthContext);
    const [dataForChart, setDataForChart] = useState(null);
    const [dataForProvisioning, setDataForProvisioning] = useState({ dataSubscription: null, dataFirewall: null, dataCertificats: null, dataContract: null });
    const [provisioningInitializeData, setProvisioningInitializeData] = useState({ dataSubscription: null, dataFirewall: null, dataCertificats: null, dataContract: null });
    const [isInitialized, setIsInitialized] = useState(false);
    const [reInitialized, setReInitialized] = useState(false);
    const [mergedData, setMergedData] = useState([]);
    const [filterType, setFilterType] = useState([]);
    const [stateUniqueDataSelect, setStateUniqueDataSelect] = useState(false);
    const [stateQueryRow, setStateQueryRow] = useState({ state: false, name: null, source: null, type: null });
    const navigate = useNavigate();
    const today = endOfDay(new Date(), 23);
    const week = startOfDay(subDays(today, 23));
    const afterOfDays = addDays(today, 90);
    const [date, setDate] = useState([null, afterOfDays]);
    const [changingDate, setChangingDate] = useState(false)
    const [minDate, setMinDate] = useState(null); 
    const [maxDate, setMaxDate] = useState(null);
    const { t, i18n } = useTranslation();
    const language = i18n.language === 'fr' ? 'fr' : 'en';

    const Ranges = [
        { label: 'Aujourd\'hui', value: [new Date(), new Date()], placement: 'left' },
        { label: 'Hier', value: [addDays(new Date(), -1), addDays(new Date(), -1)], placement: 'left' },
        { label: 'Les 7 derniers jours', value: [subDays(new Date(), 6), new Date()], placement: 'left' },
        { label: 'Les 30 derniers jours', value: [subDays(new Date(), 29), new Date()], placement: 'left' },
        { label: 'Ce mois-ci', value: [startOfMonth(new Date()), new Date()], placement: 'left' },
        { label: 'Le mois dernier', value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))], placement: 'left' },
        { label: 'Tout', value: [minDate, maxDate], placement: 'left' }
    ];

    const getSubscriptions = async () => {
        const res = await axios.get(`${apiUrl}subscription/index`, {
            withCredentials: true
        });
        return res.data;
    };

    const getFirewalls = async () => {
        const res = await axios.get(`${apiUrl}license/palo/`, {
            withCredentials: true
        });
        return res.data;
    };

    const getCertficats = async () => {
        const res = await axios(`${apiUrl}certificats/`, {
            withCredentials: true
        });
        return res.data
    };

    const getContracts = async () => {
        const res = await axios.get(`${apiUrl}contract/byserial`, {
            withCredentials: true,
        });
        return res.data;
    };

    const subscription = useQuery({
        queryKey: ["subscription"],
        queryFn: getSubscriptions
    });

    const firewall = useQuery({
        queryKey: ["licences"],
        queryFn: getFirewalls
    });

    const certificats = useQuery({
        queryKey: ["certificats"],
        queryFn: getCertficats
    })

    const contract = useQuery({
        queryKey: ["contracts"],
        queryFn: getContracts,
    });

    useEffect(() => {
        if (date) {
            setChangingDate(true);
        }
    }, [date]);

    useEffect(() => {
        if (subscription.isSuccess && firewall.isSuccess && certificats.isSuccess && contract.isSuccess) {
            if(subscription.data === 'No data' && firewall.data[0].data === 'No license palo found' && certificats.data.length === 0 && contract.data === 'No data'){
                return setDataForChart(undefined);
            } else {
                getDataTimeline();
                setIsInitialized(true);
            }
        }
    }, [subscription.isSuccess, firewall.isSuccess, certificats.isSuccess, contract.isSuccess, filterType, language, date]);

    useEffect(() => {
        if (dataForChart) {
            initTimeline(dataForChart);
        }
    }, [dataForChart]);

    const updateProvisioningData = () => {
        setProvisioningInitializeData({
            dataSubscription: dataForProvisioning.dataSubscription,
            dataFirewall: dataForProvisioning.dataFirewall,
            dataCertificats: dataForProvisioning.dataCertificats,
            dataContract: dataForProvisioning.dataContract
        });
    };

    useEffect(() => {
        if (isInitialized && changingDate) {
            if (filterType.length === 0) {
                updateProvisioningData();
            } else {
                setFilterType([]);
                setReInitialized(true);
            }
            setChangingDate(false);
            setStateUniqueDataSelect(false);
        }
        
        if (stateQueryRow.type === 'delete') {
            setFilterType([]);
            setChangingDate(false);
        }
    }, [isInitialized, dataForProvisioning, changingDate, filterType, stateQueryRow.type]);
    
    useEffect(() => {
        updateProvisioningData();
        setReInitialized(false);
    }, [reInitialized]);

    const initTimeline = (dataForChart) => {
        var options = {
            initial_zoom: 1,
            language: language
        };
        let timeline = new Timeline('timeline', dataForChart, options);

        timeline.on('loaded', function (data) {
            data.events.forEach(eventData => {
                const eventType = eventData.unique_id.split('-')[0];
                const eventId = eventData.unique_id.split('-')[1];
                const eventElement = document.getElementById(`${eventData.unique_id}-marker`);
                const tlTimemarkerContents = eventElement.querySelectorAll('.tl-timemarker-content-container');
                const elementLinkSubscription = document.getElementById(`${eventId}-subscription`);
                const elementLinkCertificats = document.getElementById(`${eventId}-certificat`);
                const elementLinkFirewall = document.getElementById(`${eventId}-firewall`);
                const elementLinkContract = document.getElementById(`${eventId}-contract`);

                tlTimemarkerContents.forEach(contentElement => {
                    const name = user.entreprise === 27 ? eventId.replace(/(sub)(\d+)/, '$1_$2') : eventId;
                    switch (eventType) {
                        case 'subscription':
                            elementLinkSubscription.addEventListener('click', () => handleLinkClick(`/subscription?subscriptionId=${name}&referrer=timeline`));
                            contentElement.classList.add('subscription');
                            break;
                        case 'certificat':
                            elementLinkCertificats.addEventListener('click', () => handleLinkClick(`/certificats?certificatId=${eventId}&referrer=timeline`));
                            contentElement.classList.add('certificats');
                            break;
                        case 'contract':
                            elementLinkContract.addEventListener('click', () => handleLinkClick(`/Smartnet?contractId=${eventId}&referrer=timeline`));
                            contentElement.classList.add('contracts');
                            break;
                        case 'firewall':
                            elementLinkFirewall.addEventListener('click', () => handleLinkClick(`/licences/paloalto?paloaltoId=${eventId}&referrer=timeline`));
                            contentElement.classList.add('firewall');
                            break;
                        default:
                            break;
                    }
                });
            });
            const todayIndex = data.events.findIndex(event => event.unique_id === 'tl-today');
            if (todayIndex !== -1) {
                timeline.options.start_at_slide = todayIndex;
            }
        });

        const handleLinkClick = (to) => {
            navigate(to);
        };

        const mergedSubscriptionData = dataForProvisioning.dataSubscription.map(item => ({ name: item.subscriptionId.replace('_', ''), type: item.transactionType, serial_number: item.offerType, endDate: item.endDate, source: 'subscription' }));
        const mergedFirewallData = dataForProvisioning.dataFirewall.map(item => ({ name: item.device_name, type: item.nom_license, serial_number: item.serial_number, endDate: item.date_fin, license_id: item.license_id, auth_code: item.auth_code, id: item.id, palo_id: item.palo_id, source: 'pare-feu' }));
        const mergedCertificatsData = dataForProvisioning.dataCertificats.map(item => ({ name: item.certificat, type: item.type, serial_number: item.fonction, endDate: item.expiration, id: item.id, plaque: item.plaque, source: 'certificat' }));
        const mergedContractData = dataForProvisioning.dataContract.map(item => ({ name: item.contract_number, type: item.itemType, serial_number: item.serial_numbers, endDate: item.endDate, source: 'contract' }));

        setMergedData([
            ...mergedSubscriptionData,
            ...mergedFirewallData,
            ...mergedCertificatsData,
            ...mergedContractData
        ]);
    };

    const getDataTimeline = () => {
        let dataSubscription = 'No data';
        let dataFirewall = 'No data';
        let dataCertificats = 'No data';
        let dataContract = 'No data';
        const contractRegex = /^Contract\./;

        if (filterType.length === 0) {
            dataSubscription = subscription.data;
            dataFirewall = firewall.data;
            dataCertificats = certificats.data;
            dataContract = contract.data !== 'No data' ? contract.data.filter(item => !contractRegex.test(item.contract_number)) : 'No data';
        }

        filterType.forEach(type => {
            switch (type) {
                case 'subscription':
                    dataSubscription = subscription.data;
                    break;
                case 'firewall':
                    dataFirewall = firewall.data;
                    break;
                case 'certificat':
                    dataCertificats = certificats.data;
                    break;
                case 'contract':
                    dataContract = contract.data.filter(item => !contractRegex.test(item.contract_number));
                    break;
                default:
                    break;
            }
        });

        const allDates = [
            ...(subscription.data !== 'No data' ? subscription.data.map(item => parseISO(item.endDate)) : []),
            ...(firewall.data !== 'No data' ? firewall.data.map(item => parseISO(item.date_fin)) : []),
            ...(certificats.data !== 'No data' ? certificats.data.map(item => parseISO(item.expiration)) : []),
            ...(contract.data !== 'No data' ? contract.data.filter(item => !contractRegex.test(item.contract_number)).map(item => parseISO(item.endDate)) : [])
        ];

        const validDates = allDates.filter(date => !isNaN(date));
        const minDate = validDates.length > 0 ? new Date(Math.min(...validDates)) : null;
        const maxDate = validDates.length > 0 ? new Date(Math.max(...validDates)) : null;

        setMinDate(minDate);
        setMaxDate(maxDate);

        const [startDate, endDate] = date; 
        const actualStartDate = startDate || minDate;
        const actualEndDate = endDate || maxDate;

        if(!startDate) {
            setDate([minDate, actualEndDate]);
        }
        
        const isDateInRange = (date) => isWithinInterval(date, { start: startOfDay(actualStartDate), end: endOfDay(actualEndDate) });
        const filteredDataSubscription = dataSubscription !== 'No data' ? dataSubscription.filter(item => isDateInRange(parseISO(item.endDate))) : [];
        const filteredDataFirewall = dataFirewall !== 'No data' ? dataFirewall.filter(item => isDateInRange(parseISO(item.date_fin))) : [];
        const filteredDataCertificats = dataCertificats !== 'No data' ? dataCertificats.filter(item => isDateInRange(parseISO(item.expiration))) : [];
        const filteredDataContract = dataContract !== 'No data' ? dataContract.filter(item => isDateInRange(parseISO(item.endDate))) : [];

        handleDataForChart(filteredDataSubscription, filteredDataFirewall, filteredDataCertificats, filteredDataContract);
    }

    const handleDataForChart = (dataSubscription, dataFirewall, dataCertificats, dataContract) => {
        const events = [{
            start_date: {
                month: new Date().getMonth() + 1,
                day: new Date().getDate(),
                year: new Date().getFullYear()
            },
            text: {
                headline: t("Today")
            },
            unique_id: 'tl-today'
        }];

        const dataProvisioningSubscription = [];
        const dataProvisioningFirewall = [];
        const dataProvisioningCertificats = [];
        const dataProvisioningContract = [];

        const formatEventData = (data, type) => {
            if (data !== 'No data') {
                data.forEach(item => {
                    const endDate = dateProvisioning(item.endDate || item.expiration || item.date_fin);
                    if (item.data !== "No license palo found") {
                        const event = {
                            start_date: {
                                month: endDate.month,
                                day: endDate.day,
                                year: endDate.year
                            },
                            text: {
                                headline: "",
                                text: ""
                            },
                            unique_id: "",
                        };
                        switch (type) {
                            case 'Subscription':
                                const subscriptionId = item.subscriptionId.split('-').slice(0).join('-').replace('_', '');
                                event.text.headline = `Subscription ${subscriptionId}`;
                                event.text.text = `
                                    <p>${item.offerType || ""}</p>
                                    <p>${item.transactionType || ""}</p>
                                    <a class="link-timeline" id="${subscriptionId.toLowerCase()}-subscription">${t('En savoir plus')}</a>
                                `;
                                dataProvisioningSubscription.push(item);
                                break;
                            case 'Firewall':
                                event.text.headline = `Firewall ${item.serial_number}`;
                                event.text.text = `
                                    <p>${item.nom_license || ""}</p>
                                    <p>${item.device_name || ""}</p>
                                    <a class="link-timeline" id="${item.license_id}-firewall">${t('En savoir plus')}</a>
                                `;
                                event.unique_id = `firewall-${item.license_id}`;
                                dataProvisioningFirewall.push(item);
                                break;
                            case 'Certificats':
                                event.text.headline = `Certificat ${item.certificat}`;
                                event.text.text = `
                                    <p>${item.fonction || ""}</p>
                                    <p>${item.type || ""}</p>
                                    <a class="link-timeline" id="${item.id}-certificat">${t('En savoir plus')}</a>
                                `;
                                event.unique_id = `certificat-${item.id}`;
                                dataProvisioningCertificats.push(item);
                                break;
                            case 'Contracts':
                                const formattedItem = item.serial_numbers.split(",").join(", ");
                                const splitItems = item.serial_numbers.split(",");
                                const displayValue = splitItems.length > 2 ? `${splitItems.slice(0, 2).join(", ")} ...` : formattedItem;
                                event.text.headline = `Contract ${item.contract_number}`;
                                event.text.text = `
                                    <p>${displayValue || ""}</p>
                                    <p>${item.serviceDescription || ""}</p>
                                    <p>${item.itemType || ""}</p>
                                    <a class="link-timeline" id="${item.contract_number.toLowerCase()}-contract">${t('En savoir plus')}</a>
                                `;
                                dataProvisioningContract.push(item);
                                break;
                            default:
                                break;
                        }
                        events.push(event);
                    }
                });
            }
        };

        formatEventData(dataSubscription, 'Subscription');
        formatEventData(dataFirewall, 'Firewall');
        formatEventData(dataCertificats, 'Certificats');
        formatEventData(dataContract, 'Contracts');

        setDataForChart({ events: events });

        setDataForProvisioning({ dataSubscription: dataProvisioningSubscription, dataFirewall: dataProvisioningFirewall, dataCertificats: dataProvisioningCertificats, dataContract: dataProvisioningContract });
    };

    const handleClickFilteredTimeline = (type) => {
        setFilterType((prevFilters) => {
            if (prevFilters.includes(type)) {
                return prevFilters.filter(item => item !== type);
            } else {
                return [...prevFilters, type];
            }
        });
        setStateUniqueDataSelect(false);
        getDataTimeline();
    };

    const handleClickPopup = () => {
        setStateQueryRow(prevState => ({ ...prevState, state: false }));
        
        setTimeout(() => {
            setStateQueryRow({ state: false, name: null, source: null, type: null });
        }, 600);
    }

    if (dataForChart === undefined) {
        return (
            <article className="page">
                <h1 className="page-title">{t("Aucune données disponible")}</h1>
            </article>
        );
    }

    return (
        <>
            {dataForChart ? (
                <article className="page page-timeline">
                    <div className={`timeline-webhook-container ${stateQueryRow.type === 'delete' ? 'popup-delete' : stateQueryRow.type === 'update' ? 'popup-update' : stateQueryRow.type === 'reset' ? 'popup-reset' : ''} ${stateQueryRow.state === false ? 'timeline-webhook-close' : ''}`}>
                        <button onClick={() => handleClickPopup()} className="close-alerts-status-length">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
                            </svg>
                        </button>
                        {stateQueryRow.source === 'subscription' ? (
                            <div>{t("La subscription")} <strong>{stateQueryRow.name}</strong> {t("vient d'être mis à jour")}</div>
                        ) : stateQueryRow.source === 'certificat' ? (
                            stateQueryRow.type === 'delete' ? (
                                <div>{t("Le certificat")} <strong>{stateQueryRow.name}</strong> {t("vient d'être supprimé")}</div>
                            ) : stateQueryRow.type === 'update' && (
                                <div>{t("Le certificat")} <strong>{stateQueryRow.name}</strong> {t("vient d'être mis à jour")}</div>
                            )
                        ) : stateQueryRow.source === 'pare-feu' && (
                            stateQueryRow.type === 'delete' ? (
                                <div>{t("Le contrat de sécurité")} <strong>{stateQueryRow.name}</strong> {t("vient d'être supprimé")}</div>
                            ) : stateQueryRow.type === 'update' && (
                                <div>{t("Le contrat de sécurité")} <strong>{stateQueryRow.name}</strong> {t("vient d'être mis à jour")}</div>
                            )
                        )}
                    </div>
                    <section className="choice-calendar-timeline">
                        <h1 className="page-title">Timeline</h1>
                        <DateRangePicker
                            ranges={Ranges}
                            cleanable={false}
                            character=" / "
                            value={date}
                            onChange={setDate}
                            format="yyyy-MM-dd"
                            defaultValue={date}
                            shouldDisableDate={date => {
                                const dateObj = startOfDay(new Date(date));
                                const minDateObj = minDate ? startOfDay(new Date(minDate)) : null;
                                const maxDateObj = maxDate ? endOfDay(new Date(maxDate)) : null;
                    
                                if (minDateObj && dateObj < minDateObj) {
                                    return true;
                                }
                                if (maxDateObj && dateObj > maxDateObj) {
                                    return true;
                                }
                                return false;
                            }}
                            locale={{
                                sunday: t('Su'),
                                monday: t('Mo'),
                                tuesday: t('Tu'),
                                wednesday: t('We'),
                                thursday: t('Th'),
                                friday: t('Fr'),
                                saturday: t('Sa'),
                                hours: t('Hours'),
                            }}
                            placement={'bottomStart'}
                        />
                    </section>
                    <div id="content-timeline">
                        <div id="timeline"></div>
                    </div>
                    <div className="container-filtering-timeline">
                        {dataForProvisioning.dataSubscription.length >= 0 && (
                            <div onClick={() => provisioningInitializeData.dataSubscription.length > 0 ? handleClickFilteredTimeline('subscription') : ''} className={filterType.includes('subscription') ? 'subscription-filter' : ''}>
                                <NumberCard title="Subscription" number={provisioningInitializeData.dataSubscription?.length ?? 0} logo={faKey} color="#6495ffd1" styleCard="subscription" stylesIcons="logo-subscription" />
                            </div>
                        )}
                        {dataForProvisioning.dataFirewall.length >= 0 && (
                            <div onClick={() => provisioningInitializeData.dataFirewall.length > 0 ? handleClickFilteredTimeline('firewall') : ''} className={filterType.includes('firewall') ? 'firewall-filter' : ''}>
                                <NumberCard title={t(`Contrats Sécurité`)} number={provisioningInitializeData.dataFirewall?.length ?? 0} logo={faShield} color="#FA8334" styleCard="firewall" stylesIcons="logo-firewall" />
                            </div>
                        )}
                        {dataForProvisioning.dataCertificats.length >= 0 && (
                            <div onClick={() => provisioningInitializeData.dataCertificats.length > 0 ? handleClickFilteredTimeline('certificat') : ''} className={filterType.includes('certificat') ? 'certificat-filter' : ''}>
                                <NumberCard title={t("Certificats")} number={provisioningInitializeData.dataCertificats?.length ?? 0} logo={faCircleCheck} color="#AEC353" styleCard="certificats" stylesIcons="logo-certificats" />
                            </div>
                        )}
                        {dataForProvisioning.dataContract.length >= 0 && (
                            <div onClick={() => provisioningInitializeData.dataContract.length > 0 ? handleClickFilteredTimeline('contract') : ''} className={filterType.includes('contract') ? 'contract-filter' : ''}>
                                <NumberCard title={t("Contrats Cisco")} number={provisioningInitializeData.dataContract?.length ?? 0} logo={faFile} color="#aa82ffda" styleCard="contracts" stylesIcons="logo-contracts" />
                            </div>
                        )}
                        {stateUniqueDataSelect || filterType.length > 0 ? (
                            <div className="button-reset-filter-timeline">
                                <button onClick={() => { setFilterType([]); setStateUniqueDataSelect(false);}}>Rénitialiser</button>
                            </div>
                        ) : null}
                    </div>
                    <div className="container-listing-timeline">
                        <TableComponent tableData={mergedData} tableColumns={ProvisionningColumn} rowComponent={RowTimelineEcp} checkbox={false} setState={setIsInitialized} stateQueryRow={stateQueryRow} setStateQueryRow={setStateQueryRow} />
                    </div>
                </article>
            ) : (
                <Loader />
            )}
        </>
    )
}