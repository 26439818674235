import React, { useMemo, useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import FiltersGraphsCallQueue from "./FiltersGraphsCallQueue/FiltersGraphsCallQueue";
import FilteringCallQueue from "./FiltersGraphsCallQueue/FilteringCallQueue";
import TypeGraphWebexRapportCallQueue from "./Rapport/TypeGraphWebexRapportCallQueue";
import EmailPopup from "../Rapport/EmailPopup";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../../../context/AuthContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FileSaverExcel from "../../../../components/FileSaverExcel";
import { MoreVert, FileDownload } from "@mui/icons-material";

export default function CallQueue({ data, people }) {
  const { apiUrl } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [filteredData, setFilteredData] = useState(data);
  const [filteredDataGroups, setFilteredDataGroups] = useState(data);
  const [linkPath, setLinkPath] = useState(null);
  const [statusReport, setStatusReport] = useState(null);
  const [dataGraph, setDataGraph] = useState(null);
  const [analyseType, setAnalyseType] = useState(null);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [formattedHeader, setFormattedHeader] = useState(false);
  const [showPostPopup, setShowPostPopup] = useState(false);
  const [showGroupPopup, setShowGroupPopup] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const chartsRef = useRef();

  const getcallQueue = async () => {
    try {
      const res = await axios.get(`${apiUrl}webex/callQueue`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
    }
  };

  // Utilisation de React Query pour gérer l'appel API
  const { data: callQueue, isLoading, isError } = useQuery({
    queryKey: ["callQueue"],
    queryFn: getcallQueue,
  });

  // Utiliser useEffect pour filtrer les appels en fonction de callednumber
  useEffect(() => {
    if (callQueue && !isLoading && !isError) {
      const filteredCalls = filteredData.filter(call =>
        Object.values(callQueue).includes(call.callednumber)
      );
      setFilteredDataGroups(filteredCalls);
    }
  }, [data, filteredData, callQueue, isLoading, isError]);
  // Fonction pour regrouper les appels par localcallid et remotecallid
  const groupedData = useMemo(() => {
    const callMap = new Map();
    const dataToGroup = linkPath === 'Analyse par groupement' ? filteredDataGroups : filteredData;

    dataToGroup.forEach((call) => {
      const { correlationid, starttime } = call;

      if (!callMap.has(correlationid)) {
        callMap.set(correlationid, {
          calls: [],
          starttime: new Date(starttime), // Stocker la date pour trier ensuite
        });
      }
      callMap.get(correlationid).calls.push(call);
    });
    // Convertir en tableau et trier par starttime
    let sortedData = Array.from(callMap.values()).sort(
      (a, b) => a.starttime - b.starttime
    );

    sortedData = sortedData.map(group => {
      const filteredCalls = group.calls.filter((call, index) => {
        // Conserver le premier appel quel que soit sa direction
        if (index === 0) return true;
        // Supprimer les appels ayant direction === 'TERMINATING' à partir du deuxième
        return call.direction !== 'TERMINATING';
      });
      return {
        ...group,
        calls: filteredCalls,
      };
    });
    return sortedData;
  }, [filteredData, filteredDataGroups, linkPath]);
  const createPdf = async () => {
    const element = chartsRef.current;
    const canvas = await html2canvas(element, {
      scale: 1.5,
      ignoreElements: (el) => el.classList.contains('pagination')
    });
    const imgData = canvas.toDataURL("image/jpeg", 0.75);

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = pdf.internal.pageSize.getHeight();

    // Définir une largeur stable pour toutes les images
    const stableWidth = 160; // Ajustez selon vos besoins
    const imgHeight = (imgProperties.height * stableWidth) / imgProperties.width; // Hauteur proportionnelle

    // Charger le logo
    const logoSrc = '/elitlogo.jpg'; // Remplacez par le chemin de votre logo

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    try {
      const logo = await loadImage(logoSrc);

      // Ajouter le logo au PDF
      const logoWidth = 30; // Ajustez selon vos besoins
      const logoHeight = (logo.height * logoWidth) / logo.width; // Hauteur proportionnelle
      const logoX = 10; // Position X du logo
      const logoY = 10; // Position Y du logo

      pdf.addImage(logo, 'JPEG', logoX, logoY, logoWidth, logoHeight);

      // Définir les titres
      const title1 = "ECP - Rapport Interactif"; // Premier titre
      const title2 = linkPath; // Deuxième titre
      const titleMargin = 20; // Marge en haut pour le premier titre

      // Ajouter le premier titre
      pdf.setFontSize(18); // Taille de police pour le titre
      pdf.text(title1, pdfWidth / 2, titleMargin, { align: "center" }); // Titre centré

      // Ajouter le deuxième titre juste en dessous
      pdf.setFontSize(14); // Taille de police pour le sous-titre
      const title2Margin = titleMargin + 10; // Espace pour le deuxième titre
      pdf.text(title2, pdfWidth / 2, title2Margin, { align: "center" }); // Sous-titre centré

      // Centrer l'image horizontalement et ajuster position Y
      const positionX = (pdfWidth - stableWidth) / 2; // Centrer horizontalement
      const positionY = title2Margin + 10; // Position Y après le sous-titre

      // Ajouter l'image au PDF
      pdf.addImage(imgData, "JPEG", positionX, positionY, stableWidth, imgHeight);

      // Retourner le blob PDF
      return new Blob([pdf.output('blob')], { type: "application/pdf" });
    } catch (error) {
      console.error("Erreur lors du chargement de l'image du logo :", error);
    }
  };

  const sendPdf = async (pdfBlob, email) => {
    const formData = new FormData();
    formData.append("file", pdfBlob, "interface-data.pdf");
    formData.append("email", email);
    try {
      const response = await axios.post(`${apiUrl}webex/mailRapport`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setEmailSend('Succes');
      return response.data;
    } catch (error) {
      console.error("Erreur lors de l'envoi du rapport par mail :", error);
    }
  };

  const handleGenerateAndSendPdf = async (email) => {
    try {
      const pdfBlob = await createPdf();
      await sendPdf(pdfBlob, email);
    } catch (error) {
      console.error("Erreur dans le processus d'envoi du PDF :", error);
    }
  };

  const handleClickPopup = () => {
    setEmailSend(false);
  }

  return (
    <div id="callqueue">
      {emailSend && (
        <article className="page-timeline">
          <div className={`timeline-webhook-container popup-update`}>
            <button onClick={() => handleClickPopup()} className="close-alerts-status-length">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
              </svg>
            </button>
            <div>Email envoyé avec succès</div>
          </div>
        </article>
      )}
      <div className='tooltip-csv-report'>
        {statusReport === "success" && (
          <div className='tooltip-csv-tableWebex'>
            <div className="tooltip-csv-tableWebex-content-open">
              <span>Exporter</span>
              <MoreVert />
            </div>
            <span className='tooltip-csv-tableWebex-button'>
              <div>
                <FileSaverExcel data={[dataGraph]} name={`${linkPath}`} formattedHeader={formattedHeader}/>
                <span className="saveCSVWebexCalling" onClick={() => setShowEmailPopup(true) + setShowPostPopup(false)}><FileDownload />Exporter en PDF et envoyer par email</span>
              </div>
            </span>
          </div>
        )}
      </div>
      <div className="statistics-report">
        <FiltersGraphsCallQueue linkPath={linkPath} setLinkPath={setLinkPath} />
        {linkPath !== null && callQueue && filteredDataGroups && (
          <FilteringCallQueue data={data} people={people} setFilteredData={setFilteredData} reportType={linkPath} setStatusReport={setStatusReport} setAnalyseType={setAnalyseType} showPostPopup={showPostPopup} setShowPostPopup={setShowPostPopup} showGroupPopup={showGroupPopup} setShowGroupPopup={setShowGroupPopup} setShowEmailPopup={setShowEmailPopup} callQueue={callQueue} />
        )}
      </div>
      {showEmailPopup && (
        <EmailPopup
          onSubmit={(email) => {
            setShowEmailPopup(false);
            handleGenerateAndSendPdf(email);
          }}
          onCancel={() => setShowEmailPopup(false)}
        />
      )}
      <div className="rapport-pdf">
        {statusReport === "success" && (
          <>
            <div ref={chartsRef} className="container-statistics-webex-report">
              <TypeGraphWebexRapportCallQueue data={groupedData} callQueue={callQueue} people={people} reportType={linkPath} analyseType={analyseType} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}