import React, { useEffect, useState } from "react";
import Loader from "../../../../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TypeCallQueue({ data, callQueue, setDataGraph, setFormattedHeader }) {  
    const [groupSurvey, setGroupSurvey] = useState();
    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        handleGraphGroupSurvey(data);
    }, [data]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    const handleGraphGroupSurvey = (data) => {
        let callqueue = {};
        data.map(item => {
            if (Array.isArray(item.calls)) {
                return item.calls.filter(call => {
                    const group = call.callednumber || 'Unknown';
                    if (!callqueue[group]) {
                        callqueue[group] = {
                            Outgoing: {
                                total: 0,
                                durationTotal: 0,
                                averageduration: 0,
                                response: 0,
                                averageresponse: 0,
                                noresponse: 0,
                                ringduration: 0,
                                averageringduration: 0
                            },
                            Incoming: {
                                total: 0,
                                durationTotal: 0,
                                averageduration: 0,
                                response: 0,
                                averageresponse: 0,
                                noresponse: 0,
                                ringduration: 0,
                                averageringduration: 0
                            }
                        };
                    }
                    if (call.direction === "TERMINATING") {
                        callqueue[group].Outgoing.total += 1;
                        callqueue[group].Outgoing.durationTotal += Math.floor(parseInt(call.duration) || 0);
                        callqueue[group].Outgoing.ringduration += Math.floor(parseInt(call.ringduration) || 0);
                        if (call.answerindicator === "Yes") {
                            callqueue[group].Outgoing.response += 1;
                            callqueue[group].Outgoing.averageresponse += Math.floor(parseInt(call.ringduration) || 0);
                        } else if (call.answerindicator === "No") {
                            callqueue[group].Outgoing.noresponse += 1;
                        }
                    } else if (call.direction === "ORIGINATING") {
                        callqueue[group].Incoming.total += 1;
                        callqueue[group].Incoming.durationTotal += Math.floor(parseInt(call.duration) || 0);
                        callqueue[group].Incoming.ringduration += Math.floor(parseInt(call.ringduration) || 0);
                        if (call.answerindicator === "Yes") {
                            callqueue[group].Incoming.response += 1;
                            callqueue[group].Incoming.averageresponse += Math.floor(parseInt(call.ringduration) || 0);
                        } else if (call.answerindicator === "No") {
                            callqueue[group].Incoming.noresponse += 1;
                        }
                    }
                });
              }
              return [];
        });

        for (let group in callqueue) {
            let Outgoing = callqueue[group].Outgoing;
            let Incoming = callqueue[group].Incoming;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;
            Outgoing.averageringduration = Outgoing.ringduration > 0 ? Outgoing.response + Outgoing.noresponse / Outgoing.ringduration : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;
            Incoming.averageringduration = Incoming.ringduration > 0 ? Incoming.response + Incoming.noresponse / Incoming.ringduration : 0;

            Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
            Outgoing.averageduration = formatDuration(Outgoing.averageduration);
            Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);
            Outgoing.ringduration = formatDuration(Outgoing.ringduration);
            Outgoing.averageringduration = formatDuration(Outgoing.averageduration);

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);
            Incoming.ringduration = formatDuration(Incoming.ringduration);
            Incoming.averageringduration = formatDuration(Incoming.averageringduration);
        }
        setGroupSurvey(callqueue);
        const formattedData = formatForExcel(callqueue);
        setDataGraph(formattedData);
    }
    const formatForExcel = (data) => {
        const formattedData = [];
    
        // Itérer sur chaque groupe (group)
        Object.entries(data).forEach(([groupKey, groupData]) => {
        // 2ème ligne: Nom du groupe (callinglineid ou group)
        formattedData.push([`${Object.keys(callQueue).find(key => callQueue[key] === groupKey)} : ${groupKey}`, "", "", "", "", ""]);

        formattedData.push([
            "Nombre d'appels répondu",
            groupData.Incoming.response || 0, // Entrant
            "" // Pas de valeur de total ici
        ]);

        formattedData.push([
            "Durée moyenne pour répondre",
            groupData.Incoming.averageresponse || "00:00:00", // Entrant
            "" // Pas de valeur pour le total
        ]);

        formattedData.push([
            "Nombre d'appels non répondu",
            groupData.Incoming.noresponse || 0, // Entrant
            "" // Pas de valeur de total ici
        ]);

        formattedData.push([
            "Durée totale d'appels",
            groupData.Incoming.durationTotal || "00:00:00", // Entrant
            "" // Pas de total global disponible
        ]);

        formattedData.push([
            "Durée moyenne d'appels",
            groupData.Incoming.averageduration || "00:00:00", // Entrant
            "" // Pas de valeur pour le total
        ]);

        formattedData.push([
            "Durée totale de d'attente",
            groupData.Incoming.ringduration || "00:00:00", // Entrant
            "" // Pas de valeur pour le total
        ]);

        formattedData.push([
            "Durée moyenne de d'attente",
            groupData.Incoming.averageringduration || "00:00:00", // Entrant
            "" // Pas de valeur pour le total
        ]);

        // Ajouter une ligne vide pour séparer les groupes
        formattedData.push([]);
    });
    setFormattedHeader(true);
    return formattedData;
};
    
    return (
        <div className="localitySurvey">
            {groupSurvey ? (
                <div>
                    <table className="localitySurvey-entrants">
                        <thead>
                            <tr>
                                <th title="Groupement">{t("Groupe")}</th>
                                <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                <th title="Durée totale d'attente">{t("Durée totale d'attente")}</th>
                                <th title="Durée moyenne d'attente">{t("Durée moyenne d'attente")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupSurvey).map((group, index) => (
                                <tr key={index}>
                                    <td>{ Object.keys(callQueue).find(key => callQueue[key] === group)}</td>
                                    <td>{groupSurvey[group].Incoming.response}</td>                            
                                    <td>{groupSurvey[group].Incoming.averageresponse}</td>
                                    <td>{groupSurvey[group].Incoming.noresponse}</td>
                                    <td>{groupSurvey[group].Incoming.durationTotal}</td>                             
                                    <td>{groupSurvey[group].Incoming.averageduration}</td>
                                    <td>{groupSurvey[group].Incoming.ringduration}</td>
                                    <td>{groupSurvey[group].Incoming.averageringduration}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loader/>
            )}
        </div>
    );
}